import React, { useEffect, useState } from "react";
import DotComponent from "./DotComponent";
const getRole = (role) => {
  const roleMap = { 100: "admin", 200: "developer" };
  return roleMap[role];
};
const UserInfoCard = ({ data = {} }) => {
  // Safely parse user data and provide default values
  //   console.log(onClick, "onCLick");
  const [user, setUser] = useState({});
  //   console.log(user, "user");
  const userData = (() => {
    try {
      // Handle both string and object inputs
      const parsedUser = typeof user === "string" ? JSON.parse(user) : user;

      return {
        name: parsedUser.name || "Unknown User",
        email: parsedUser.email || "No email",
        role: getRole(parsedUser.role) || "N/A",
        id: parsedUser._id?.$oid || parsedUser._id || "Unknown ID",
        modules: Array.isArray(parsedUser.modules)
          ? parsedUser.modules.map((m) => m.$numberInt || m)
          : [],
        active: parsedUser.active || null,
      };
    } catch (error) {
      return {
        name: "Invalid User Data",
        email: "Error",
        role: "N/A",
        id: "Error",
        modules: [],
        active: null,
      };
    }
  })();

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setUser(data);
    }
  }, [data]);

  // console.log(userData, "userData");

  return (
    <div className="p-4 transition-shadow duration-300 bg-white rounded-lg shadow-md hover:shadow-lg">
      <div className="mb-2 text-lg font-semibold text-gray-800 truncate">
        {userData.name}
      </div>
      <div className="space-y-2 text-sm">
        <div className="flex justify-between">
          <span className="text-gray-500">ID:</span>
          <span className="text-xs truncate max-w-[100px]">{userData.id}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-500">Email:</span>
          <span className="font-medium truncate max-w-[150px]">
            {userData.email}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-500">Role:</span>
          <span className="bg-gray-100 text-gray-800 px-2 py-0.5 rounded text-xs">
            {userData.role}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-500">Modules:</span>
          <div className="flex gap-1">
            {userData.modules.map((module, index) => (
              <span
                key={index}
                className="bg-blue-50 text-blue-600 px-1.5 py-0.5 rounded text-xs"
              >
                {module}
              </span>
            ))}
          </div>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-500">Active:</span>
          <span className="">
            <DotComponent input={userData.active} />
            {/* {userData.active ? "true" : "false"} */}
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserInfoCard;
