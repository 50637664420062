import MUIDataTable from "mui-datatables";
// import Datatable from "../Comp/Table/Datatable";
import { Button, ButtonGroup, Tooltip, Typography } from "@mui/material";
import { json, NavLink } from "react-router-dom";

import { Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Image } from "antd";
import Datatable from "../../../Comp/Table/Datatable";
import useStore from "../../../Hooks/useStore";
import axios from "../../../api/axios/axios";
import { Chip } from "@mui/material";
import AppConstrains from "../../../AppConstrains/AppConstrains";
const ViewGroupComponent = ({ tableData, loading }) => {
  const { setFetchAgain } = useStore();

  const columns = [
    {
      name: "_id",
      label: "Action",
      options: {
        customBodyRender: (id) => {
          const fullRowData = tableData.find((data) => data._id === id);
          // console.log(fullRowData, "fullRowData");

          // let data = allStoresData.filter((row) => row.store_id === id)[0];
          return (
            <ButtonGroup orientation="vertical" variant="text" color="success">
              <NavLink to="/creategroup" state={{ fullRowData }}>
                <Button color="success">
                  <strong>Edit</strong>
                </Button>
              </NavLink>
              {/* <Popconfirm
                                placement="right"
                                title="Delete Store"
                                description="Do you really want to delete the store?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => console.log(id)}
                            >
                                <Button color="error">
                                    <strong>Delete</strong>
                                </Button>
                            </Popconfirm> */}
            </ButtonGroup>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "defaultGroup",
      label: "Default Group",
      options: {
        customBodyRender: (defaultGroup) => {
          // console.log(defaultGroup, "defaultGroup");
          if (defaultGroup) return 1;
          else return 0;
          // return defaultGroup?.map((default) => {
          //   return (
          //     <Chip label={default} size="small" variant="outlined" />
          //   );
          // });
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "categories",
      label: "Categories",
      options: {
        customBodyRender: (categories) => {
          // console.log(categories, "categories");
          return (
            <div className="flex gap-2">
              {categories?.map((category) => {
                return (
                  <Chip label={category} size="small" variant="outlined" />
                );
              })}
            </div>
          );
        },
        filter: true,
        sort: true,
      },
    },
    // {
    //     name: "store_page_url",
    //     label: "Store Page URL",
    //     options: {
    //         customBodyRender: (link) => {
    //             return (
    //                 <a href={link} target="_blank" rel="noreferrer">{link}</a>
    //             )
    //         },
    //         filter: true,
    //         sort: true,
    //     }
    // },

    {
      name: "stores",
      label: "Stores",
      options: {
        customBodyRender: (stores) => {
          // console.log(stores, "categories");
          return (
            <div className="flex gap-2">
              {stores?.map((store) => {
                return <Chip label={store} size="small" variant="outlined" />;
              })}
            </div>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "keywords",
      label: "Keywords",
      options: {
        customBodyRender: (keywords) => {
          // console.log(categories, "categories");
          return (
            <div className="flex gap-2">
              {keywords?.map((keyword) => {
                return <Chip label={keyword} size="small" variant="outlined" />;
              })}
            </div>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "priority",
      label: "Priority",
      options: {
        customBodyRender: (brand) => {
          return <div style={{ width: "12px" }}>{brand}</div>;
        },
        filter: true,
        sort: true,
      },
    },

    {
      name: "icon",
      label: "Icon",
      options: {
        customBodyRender: (link) => {
          return (
            <div
              // className="size-[40px] rounded overflow-hidden duration-150"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "4px",
                overflow: "hidden",
                transitionDuration: "0.150s",
              }}
            >
              <Image src={`${AppConstrains.baseURL}/imagePath/group/` + link} />
            </div>
          );
        },
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "inserted_at",
    //   label: "Inserted At",
    //   options: {
    //     customBodyRender: (isoDate) => {
    //       // console.log(link, "link");
    //       const date = new Date(isoDate);
    //       return (
    //         <div style={{ width: "115px" }}>
    //           {date.toLocaleString("en-US", {
    //             year: "numeric",
    //             month: "long",
    //             day: "numeric",
    //             hour: "numeric",
    //             minute: "numeric",
    //             hour12: true,
    //           })}
    //         </div>
    //       );
    //     },
    //     filter: true,
    //     sort: true,
    //   },
    // },
    // {
    //   name: "updated_at",
    //   label: "Updated At",
    //   options: {
    //     customBodyRender: (isoDate) => {
    //       // console.log(link, "link");
    //       const date = new Date(isoDate);
    //       return (
    //         <div style={{ width: "115px" }}>
    //           {date.toLocaleString("en-US", {
    //             year: "numeric",
    //             month: "long",
    //             day: "numeric",
    //             hour: "numeric",
    //             minute: "numeric",
    //             hour12: true,
    //           })}
    //         </div>
    //       );
    //     },
    //     filter: true,
    //     sort: true,
    //   },
    // },

    {
      name: "active",
      label: "Status",
      options: {
        customBodyRender: (active, tableMeta) => {
          // console.log(active, "active");
          var data = {};
          data._id = tableMeta.rowData[0];
          data.active = active ? 1 : 0;
          if (active)
            return (
              <Popconfirm
                placement="topRight"
                title="Deactivate Store"
                description="Do you really want to deactivate the store?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleStatus(data)}
              >
                <Button color="error" size="small" variant="outlined">
                  <b>Deactivate</b>
                </Button>
              </Popconfirm>
            );
          else
            return (
              <Popconfirm
                placement="topRight"
                title="Activate Store"
                description="Do you really want to activate the store?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleStatus(data)}
              >
                <Button color="success" size="small" variant="outlined">
                  <b>Activate</b>
                </Button>
              </Popconfirm>
            );
        },
        filter: true,
        sort: true,
      },
    },
  ];
  const handleStatus = async (data) => {
    const UPDATE_DEAL_URL = "/api/deals/updateappdealsgroup";

    try {
      const response = await axios.put(UPDATE_DEAL_URL, {
        _id: data._id,
        active: !data.active,
      });
      toast.success(response.data.msg);
      setFetchAgain((prev) => !prev);
    } catch (error) {
      toast.error(error.response.data.msg);
      console.log(error);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Datatable
        name="Store Data"
        columns={columns}
        data={tableData}
        loading={loading}
      />
    </div>
  );
};
export default ViewGroupComponent;
