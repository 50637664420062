import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import HelpIcon from "@mui/icons-material/Help";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  IconButton,
  Grid,
  Popover,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import axios from "../api/axios/axios";
import useAxiosPrivate from "../Hooks/useAxiosPrivate";
import useStore from "../Hooks/useStore";

const lightColor = "rgba(255, 255, 255, 0.7)";

function Header(props) {
  const navigate = useNavigate();
  const { auth } = useStore();
  console.log(auth, "auth");
  const { onDrawerToggle } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  // console.log(anchorEl, "anchorEl");
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const Heading = location.pathname.split("/")[1] || "DealsApp";

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    const LOGOUT_URL = "/api/auth/logout";
    const response = await axiosPrivate.get(LOGOUT_URL).then(() => {
      navigate("/signin");
    });

    // console.log(response, "response");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const isOpen = Boolean(anchorEl);
  const id = isOpen ? "avatar-popover" : undefined;
  // console.log(location.pathname.split("/")[1], "location");
  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        {/* <Toolbar>
          <Grid container spacing={1} sx={{ alignItems: "center" }}>
            <Grid item xs />
            <Grid item>
              <Link
                href="/"
                variant="body2"
                sx={{
                  textDecoration: "none",
                  color: lightColor,
                  "&:hover": {
                    color: "common.white",
                  },
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Go to docs
              </Link>
            </Grid>
            <Grid item>
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton color="inherit" sx={{ p: 0.5 }}>
                <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar> */}
      </AppBar>
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onDrawerToggle}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid container spacing={1} sx={{ alignItems: "center" }}>
            <Grid item xs>
              <Typography
                color="inherit"
                variant="h5"
                component="h1"
                className="capitalize"
              >
                {Heading}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Link
                href="/"
                variant="body2"
                sx={{
                  textDecoration: "none",
                  color: lightColor,
                  "&:hover": {
                    color: "common.white",
                  },
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Go to docs
              </Link>
            </Grid> */}
            <Grid item>
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton color="inherit" sx={{ p: 0.5 }} onClick={handleOpen}>
                <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" />
              </IconButton>
              <Popover
                id={id}
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <List>
                  {auth?.email && (
                    <ListItem
                      button
                      onClick={() => navigate("/profile")}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <ListItemIcon sx={{ color: "inherit" }}>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary="Profile" />
                    </ListItem>
                  )}

                  {auth?.email ? (
                    <ListItem
                      button
                      onClick={() => handleLogout()}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <ListItemIcon sx={{ color: "inherit" }}>
                        <LogoutIcon />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  ) : (
                    <ListItem
                      button
                      onClick={() => navigate("/signin")}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <ListItemIcon sx={{ color: "inherit" }}>
                        <LogoutIcon />
                      </ListItemIcon>
                      <ListItemText primary="Login" />
                    </ListItem>
                  )}
                  {/* <ListItem
                    button
                    onClick={() => handleLogout()}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <ListItemIcon sx={{ color: "inherit" }}>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItem> */}
                </List>
              </Popover>
            </Grid>

            {/* <Grid item>
              <Button
                sx={{ borderColor: lightColor }}
                variant="outlined"
                color="inherit"
                size="small"
              >
                Web setup
              </Button>
            </Grid> */}
            {/* <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Grid>
        </Toolbar>
      </AppBar>
      {/* <AppBar
        component="div"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Tabs value={0} textColor="inherit">
          <Tab label="Users" />
          <Tab label="Sign-in method" />
          <Tab label="Templates" />
          <Tab label="Usage" />
        </Tabs>
      </AppBar> */}
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
