import React, { useEffect, useState } from "react";
import { Paper, Stack, Toolbar, Typography, Button, Menu } from "@mui/material";
import MUIDataTable from "mui-datatables";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import PrintIcon from "@mui/icons-material/Print";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";

function Datatable(props) {
  // For custom toolbar

  // console.log(props.data, "propsdata");
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);
  const rows = props?.data;
  // console.log(rows, "rows");
  const options = {
    elevation: 0,
    filterType: "multiselect",
    selectableRows: "none",
    selectableRowsHeader: false,
    download: true,
    print: true,
    // search: true,
    searchPlaceholder: "Enter search key",
    searchAlwaysOpen: true,

    serverSide: true,
    page: props?.page,
    rowsPerPage: props?.rowsPerPage,
    count: props?.totalCount,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          props.setPage(tableState.page);
          break;
        case "changeRowsPerPage":
          props.setRowsPerPage(tableState.rowsPerPage);
          props.setPage(0);
          break;
      }
    },
    textLabels: {
      body: {
        noMatch: props?.loading
          ? "Loading data..."
          : "Sorry, no matching records found",
      },
    },
    // rowsPerPageOptions: [10, 20, 50, props.data.length],
    // fixedHeader: true,
    // tableBodyHeight: "calc(100vh - 280px)",
    // tableBodyMaxHeight: "calc(100vh - 200px)",

    // loading: loading,
  };

  // Export to pdf handler
  function exportToPdf() {
    const orientation = props.columns.length > 5 ? "landscape" : "portrait";
    const doc = new jsPDF(orientation);
    doc.text(`${props.name}`, 15, 10);
    doc.autoTable({
      theme: "grid",
      columns: props.columns.map((row) => ({
        header: row.label,
        dataKey: row.name,
      })),
      body: props.data,
    });
    doc.save(`${props.name}.pdf`);
  }

  return (
    <Paper elevation={3}>
      {/* Custom Toolbar */}
      <Toolbar
        mt={3}
        sx={{
          padding: "1.2rem",
          borderBottom: "2px solid var(--primary-color)",
          position: "sticky",
          top: 0,
          zIndex: 2,
          backgroundColor: "#fff",
        }}
      >
        <Typography
          sx={{
            flex: "1 1 100%",
            color: "var(--primary-color)",
            fontWeight: "bold",
          }}
          variant="h5"
          id="tableTitle"
        >
          {/* {`${props.name} (${props.data.length})`} */}
        </Typography>
        <Button
          id="resources-btn"
          variant="outlined"
          color="primary"
          onClick={(event) => setAnchor(event.currentTarget)}
          aria-controls={open ? "resources-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? true : undefined}
          startIcon={<FileDownloadIcon />}
          endIcon={<KeyboardArrowDownIcon />}
          sx={{
            position: "sticky",
            right: 0,
            top: 0,
            zIndex: 2,
          }}
        >
          <strong>Export</strong>
        </Button>

        <Menu
          id="resources-menu"
          anchorEl={anchor}
          open={open}
          MenuListProps={{ "aria-labelledby": "resources-button" }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setAnchor(null)}
        >
          <Stack direction="column" px={1}>
            <CSVLink
              filename={`${props.name}.csv`}
              headers={props.columns.map((row) => ({ ...row, key: row.name }))}
              data={props.data}
              target="_blank"
            >
              <Button
                onClick={() => setAnchor(null)}
                variant="text"
                color="success"
                // size='small'
                startIcon={<DownloadForOfflineIcon />}
              >
                <strong>Export to CSV</strong>
              </Button>
            </CSVLink>
            {/* Export to pdf */}
            <div onClick={() => setAnchor(null)}>
              <Button
                onClick={exportToPdf}
                variant="text"
                color="error"
                // size='small'
                startIcon={<PrintIcon />}
              >
                <strong>Export to PDF</strong>
              </Button>
            </div>
          </Stack>
        </Menu>
      </Toolbar>
      <div
        style={{
          overflowY: "hidden",
          flex: 1,
          padding: "0 1.5rem",
        }}
      >
        <MUIDataTable
          key={`page-${props.page}-${rows.length}`}
          className="m-3"
          data={props.loading ? [{ placeholder: "Loading..." }] : rows}
          columns={props.columns}
          options={options}
        />
      </div>
    </Paper>
  );
}

export default Datatable;
