import { Card, CardContent, Chip, Skeleton } from "@mui/material";
import { User, Mail } from "lucide-react";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import useStore from "../../Hooks/useStore";

const getRole = (role) => {
  const roleMap = { 100: "admin", 200: "developer" };
  return roleMap[role];
};

const Profile = () => {
  const axiosPrivate = useAxiosPrivate();
  // const [userData, setUserData] = useState({});
  // const userDataRef = useRef();
  const { setUserProfile, userProfile } = useStore();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getUserProfile = async () => {
      try {
        setLoading(true);
        const GET_USER_PROFILE_URL = "/api/auth/getprofile";
        const response = await axiosPrivate.get(GET_USER_PROFILE_URL);
        setUserProfile(response.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    if (!Object.keys(userProfile).length) {
      getUserProfile();
    }
  }, []);
  // console.log(loading);
  // console.log(userData, "userData");
  // const email = "kksolanki1503@gmail.com";
  // const name = "kamlesh kumar";

  return (
    <div className="flex items-center justify-center p-4 bg-gray-100">
      <Card className="w-full max-w-md transition-shadow duration-300 shadow-lg rounded-xl hover:shadow-2xl">
        <CardContent className="flex flex-col items-center p-6">
          <div className="flex items-center justify-center w-24 h-24 mt-4 mb-4 overflow-hidden border-4 border-blue-500 rounded-full">
            <User className="w-12 h-12 text-gray-500" />
          </div>

          <div className="w-full text-center">
            <h2 className="mb-1 text-base font-bold text-gray-800 sm:text-2xl">
              {loading ? <Skeleton /> : userProfile?.name}
            </h2>

            {loading ? (
              <Skeleton />
            ) : (
              <div className="flex items-center justify-center text-sm text-gray-600 sm:text-base">
                <Mail className="w-5 h-5 mr-2 text-blue-500" />
                <span>{userProfile?.email}</span>
              </div>
            )}

            {loading ? (
              <Skeleton />
            ) : (
              <div className="flex items-center justify-start py-2 text-sm text-gray-600 sm:text-base">
                Role:-
                <span className="flex gap-2 px-2">
                  <Chip
                    className="!cursor-pointer"
                    size="small"
                    label={getRole(userProfile?.role)}
                    variant="outlined"
                    color="warning"
                  />
                </span>
              </div>
            )}

            {loading ? (
              <Skeleton />
            ) : (
              <div className="flex items-center justify-start py-2 text-sm text-gray-600 sm:text-base">
                Modules:-
                <span className="flex flex-wrap items-start justify-start gap-2 px-2">
                  {userProfile?.modules?.map((module) => {
                    return (
                      <Chip
                        className="!cursor-pointer"
                        size="small"
                        label={module}
                        variant="outlined"
                      />
                    );
                  })}
                </span>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
export default Profile;
