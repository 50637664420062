import { useEffect, useState } from "react";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";

import useStore from "../../Hooks/useStore";
import UsersComponent from "../../Comp/User";

const Users = () => {
  const { fetchAgain } = useStore();
  const axiosPrivate = useAxiosPrivate();
  const [users, setUsers] = useState([]);
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const GetAllUsers = async () => {
      const GET_ALL_USERS_URL = "/api/auth/getallusers";
      try {
        const response = await axiosPrivate.get(GET_ALL_USERS_URL);
        // console.log(response.data);
        setUsers(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    const GetAllRoutes = async () => {
      const GET_ALL_USERS_URL = "/api/auth/getallroutes";
      try {
        const response = await axiosPrivate.get(GET_ALL_USERS_URL);
        // console.log(response.data);
        setRoutes(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    GetAllUsers();
    GetAllRoutes();
  }, [fetchAgain]);

  // console.log(users, "users");
  // console.log(routes, "routes");
  return <UsersComponent users={users} routes={routes} />;
};
export default Users;
