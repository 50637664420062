const ServerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-12 h-12 mx-auto mb-4 text-blue-600"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"
    />
  </svg>
);

const MobileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-12 h-12 mx-auto mb-4 text-green-600"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
    />
  </svg>
);

const BroadcastIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-12 h-12 mx-auto mb-4 text-purple-600"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.111 16.404a5.5 5.5 0 017.778 0M12 20h.01m-7.08-7.071c3.904-3.905 10.236-3.905 14.141 0M1.394 9.393c5.857-5.857 15.355-5.857 21.213 0"
    />
  </svg>
);

const ZapIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-8 h-8 mr-2 text-blue-600"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13 10V3L4 14h7v7l9-11h-7z"
    />
  </svg>
);

const Landing = () => {
  return (
    <div className="py-4 bg-gray-50 ">
      {/* Hero Section */}
      <main className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <section className="text-center">
          <h2 className="mb-4 text-4xl font-extrabold text-gray-900">
            Instant Deal Broadcasts Across Platforms
          </h2>
          <p className="max-w-2xl mx-auto text-xl text-gray-600">
            Seamlessly broadcast real-time deals from your backend to Android
            devices using Socket.io's powerful, low-latency communication
            technology.
          </p>
        </section>

        {/* Features Section */}
        <section id="features" className="mt-16">
          <div className="grid gap-8 md:grid-cols-3">
            {/* Feature 1: Real-time Communication */}
            <div className="p-6 text-center bg-white rounded-lg shadow-md">
              <ServerIcon />
              <h3 className="mb-2 text-xl font-semibold">
                Real-time Broadcasting
              </h3>
              <p className="text-gray-600">
                Instantly push new deals to connected Android devices without
                manual refreshing or polling.
              </p>
            </div>

            {/* Feature 2: Cross-Platform */}
            <div className="p-6 text-center bg-white rounded-lg shadow-md">
              <MobileIcon />
              <h3 className="mb-2 text-xl font-semibold">
                Multi-Platform Support
              </h3>
              <p className="text-gray-600">
                Works seamlessly across Android, web, and other platforms with
                minimal configuration.
              </p>
            </div>

            {/* Feature 3: Low Latency */}
            <div className="p-6 text-center bg-white rounded-lg shadow-md">
              <BroadcastIcon />
              <h3 className="mb-2 text-xl font-semibold">Low Latency</h3>
              <p className="text-gray-600">
                Minimal delay between deal creation and device notification,
                ensuring users get updates instantly.
              </p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
export default Landing;
