import { Paper, Typography } from "@mui/material";
import CustomInput from "../Form/CustomInput";
import CustomImageShow from "../Form/CustomImageShow";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CustomButton from "../Form/CustomButton";
import axios from "../../api/axios/axios";
import { toast } from "react-toastify";
import LineChart from "../Form/lineChart";
import { useNavigate } from "react-router-dom";
import PercentIcon from "@mui/icons-material/Percent";
import "./CreateForm.css"; // Import the CSS file
import TelegramIcon from "@mui/icons-material/Telegram";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import io from "socket.io-client";
const socket = io("https://dev.flipshope.com", {
  path: "/api/check/socket.io",
  transports: ["websocket"], // Ensure WebSocket transport
});
const CreateForm = memo(({ dealsData = {}, setLink, rowData = null }) => {
  const [CreateData, setCreateData] = useState({});
  const [isConnected, setIsConnected] = useState(false);
  const navigate = useNavigate();

  const handelChange = (event) => {
    // console.log(event, "event");
    setCreateData({ ...CreateData, [event.target.name]: event.target.value });
  };
  const sendDealsApp = (e) => {
    e.preventDefault();
    socket.emit("deals", CreateData);
    if (isConnected) {
      console.log("connected");
    } else {
      toast.error("Socket not connected");
    }
  };
  useEffect(() => {
    // Listen for connection status
    socket.on("connection", () => {
      console.log("Socket connected");
      setIsConnected(true); // Update connection status
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected");
      setIsConnected(false); // Update connection status
    });

    // Clean up the socket connection on component unmount
    return () => {
      socket.off("connection");
      socket.off("disconnect");
    };
  }, []);
  useEffect(() => {
    if (dealsData && Object?.keys(dealsData)?.length > 0) {
      setCreateData({
        ...CreateData,
        _id: dealsData?._id,
        pid: dealsData?.pid,
        sid: dealsData?.sid,
        text: dealsData?.text,
        title: dealsData?.title,
        imgurl: dealsData?.imgurl,
        mrp: dealsData?.mrp,
        active: dealsData?.active,
        category: dealsData?.category,
        sub_category: dealsData?.sub_category,
        brand: dealsData?.brand,
        latestPrice:
          dealsData?.latestPrice?.[0]?.price || dealsData?.latestPrice,
        graph_data: dealsData?.graph_data,
        aff_url: dealsData?.aff_url,
        color: dealsData?.color,
        inStock: dealsData?.inStock || dealsData?.latestPrice?.[0]?.inStock,
      });
    }
  }, [dealsData]);

  const activeOptions = useMemo(
    () => [
      { value: 1, label: "True" },
      { value: 0, label: "False" },
    ],
    []
  );

  const saveToDb = useCallback(async () => {
    const CREATE_DATA_URL = "/api/deals/savetodb";
    const UPDATE_DATA_URL = "/api/deals/updateproductdb";
    try {
      if (rowData) {
        const update = await axios.put(UPDATE_DATA_URL, {
          ...CreateData,
        });
        toast.success(update.data.message);
        navigate("/viewdeals");
      } else {
        const save = await axios.post(CREATE_DATA_URL, {
          ...CreateData,
        });
        toast.success(save.data.msg);
      }
      setCreateData({});
      setLink("");
    } catch (error) {
      toast.error(error.response.data.msg);
      console.log(error, "error in fetch db");
    }
  }, [CreateData]);

  console.log(CreateData, "createData");
  console.log(rowData, "rowData");

  return (
    <div>
      {Object?.keys(dealsData)?.length > 0 && (
        <Paper
          className="create-form-container"
          // sx={{ display: `${Object.keys(dealsData).length ? "block" : "none"}` }}
        >
          <Typography variant="h5" component="h5" className="form-title">
            Product Details
          </Typography>
          <div className="form-wrapper">
            <div className="form-row md-flex-row">
              <CustomInput
                label="Pid"
                name="pid"
                className="input-grow"
                value={CreateData.pid}
                disabled={true}
              />
              <CustomInput
                label="Sid"
                name="sid"
                className="input-grow"
                value={CreateData.sid}
                disabled={true}
              />
            </div>
            <div className="form-row">
              <CustomInput
                label="Title"
                name="title"
                className="input-grow"
                value={CreateData.title}
                handelChange={handelChange}
              />
            </div>
            <div className="form-row md-flex-row">
              <CustomInput
                label="Imgurl"
                className="input-grow"
                value={CreateData.imgurl}
                name="imgurl"
                disabled={true}
              />
              <CustomImageShow link={CreateData.imgurl} />
            </div>
            <div className="form-row md-flex-row">
              <CustomInput
                label="MRP"
                className="input-grow"
                name="mrp"
                value={CreateData.mrp}
                handelChange={handelChange}
              />
              <CustomInput
                label="Latest Price"
                className="input-grow"
                value={CreateData.latestPrice}
                name="latestPrice"
                handelChange={handelChange}
              />
            </div>

            <div className="form-row md-flex-row">
              <CustomInput
                label="In Stock"
                className="input-grow md-half-width"
                name="inStock"
                value={CreateData?.inStock}
                handelChange={handelChange}
                optionValue={true}
                withButton={true}
                activeOptions={activeOptions}
              />
              <CustomInput
                label="Color"
                className="input-grow md-half-width"
                name="color"
                value={CreateData?.color}
                handelChange={handelChange}
              />
            </div>

            <div className="form-row md-flex-row">
              <CustomInput
                label="Category"
                className="input-grow"
                name="category"
                value={CreateData.category}
                handelChange={handelChange}
              />
              <CustomInput
                label="Sub Category"
                className="input-grow"
                value={CreateData.sub_category}
                name="sub_category"
                handelChange={handelChange}
              />
            </div>
            <div className="form-row md-flex-row">
              <CustomInput
                label="Brand"
                className="input-grow"
                value={CreateData.brand}
                name="brand"
                handelChange={handelChange}
              />
              <CustomInput
                label="Affiliate Link"
                className="input-grow"
                value={CreateData.aff_url}
                name="aff_url"
                handelChange={handelChange}
              />
            </div>
          </div>
          <div className="form-row md-flex-row">
            <CustomInput
              label="Text"
              className="input-grow"
              value={CreateData.text}
              multiline={true}
              maxRows={4}
              name="text"
              handelChange={handelChange}
            />
          </div>
          <div className="mt-5">
            <LineChart graphData={CreateData?.graph_data} />
          </div>

          <div
            className=""
            style={{
              marginTop: "40px",
              marginBottom: "15px",
              gap: "16px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CustomButton
              value="Telegram"
              id="telegram"
              className=""
              variant="outlined"
              href="https://chatgpt.com/"
              target="_blank"
              icon={TelegramIcon}
            />
            <CustomButton
              value="Deals App"
              id="dealsApp"
              handleClick={sendDealsApp}
              className=""
              variant="outlined"
              href="https://chatgpt.com/"
              target="_blank"
              icon={PhoneAndroidIcon}
            />
            <CustomButton
              value={!rowData ? "Save" : "Update"}
              id="save"
              className=""
              variant="contained"
              handleClick={saveToDb}
            />
          </div>
        </Paper>
      )}
    </div>
  );
});

export default CreateForm;
