import { useEffect, useState } from "react";
import ViewGroupComponent from "../../../Comp/Group/ViewGroup/ViewGroup";
import axios from "../../../api/axios/axios";
import { toast } from "react-toastify";
import useStore from "../../../Hooks/useStore";

const ViewGroup = () => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const { fetchAgain } = useStore();

  useEffect(() => {
    const FETCH_TABLE_URL = "/api/deals/showappdealsgroup";
    const PROD_CAT_URL = "https://flipshope.com/api/prices/getprodcat";
    const STORES_URL = "https://flipshope.com/api/prices/graphstores";
    const controller = new AbortController();

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(FETCH_TABLE_URL, {
          signal: controller.signal,
        });
        const prodCatResponse = await axios.get(PROD_CAT_URL);

        const categories = await prodCatResponse.data.data;
        const storeCatReponse = await axios.get(STORES_URL);
        const stores = await storeCatReponse.data.data;

        const modifidedData = await response.data.data.map((data) => {
          data.defaultGroup = JSON.parse(data.defaultGroup);
          data.active = JSON.parse(data.active);
          // console.log(categories, "categoriesf");
          data.categories = JSON.parse(data.categories);
          // .map((id, index) => {
          //   const cat = categories.find((category) => category.cat_id === id);

          //   console.log(cat, "cat");
          //   return cat?.cat_name ? cat.cat_name : "not found";
          // });
          data.stores = JSON.parse(data.stores);
          // .map((id, index) => {
          //   const store = stores.find((store) => store.site_id === id);
          //   return store?.store_name ? store.store_name : "not found";
          // });
          data.keywords = JSON.parse(data.keywords);
          data.priority = JSON.parse(data.priority);

          return data;
        });
        // console.log(modifidedData, "modifidedData");

        // console.log(categories, stores, "testing ");

        setTableData(modifidedData);
        // setTotalCount(response?.data?.totalCount);
      } catch (error) {
        console.log(error.message);
        toast(error?.response?.data.error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();

    return () => {
      controller.abort();
    };
  }, [fetchAgain]);

  return <ViewGroupComponent tableData={tableData} loading={loading} />;
};
export default ViewGroup;
