import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse"; // For collapsible menus
import CreateIcon from "@mui/icons-material/Create";
import PreviewIcon from "@mui/icons-material/Preview";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../Hooks/useAxiosPrivate";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import PersonIcon from "@mui/icons-material/Person";
const iconMap = {
  PreviewIcon: <PreviewIcon />,
  CreateIcon: <CreateIcon />,
  PersonIcon: <PersonIcon />,
};

const getIcon = (iconKey) => {
  // console.log(iconKey, "iconKey");
  if (!iconMap[iconKey]) {
    // console.log(`Icon key "${iconKey}" is not mapped`);
    return <PriorityHighIcon />;
  }
  return iconMap[iconKey] || null;
};

const item = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const { ...other } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split("/")[1];
  const axiosPrivate = useAxiosPrivate();
  // State to track which sections are expanded
  const [expandedSections, setExpandedSections] = useState({});

  const handleToggle = (sectionId) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  };
  const [categories, setCategories] = useState([]);

  // let categories = [
  //   {
  //     id: "Deals",
  //     children: [
  //       {
  //         id: "createdeal",
  //         title: "create deal",
  //         icon: <CreateIcon />,
  //         active: path === "createdeal",
  //       },
  //       {
  //         id: "viewdeals",
  //         title: "view deals",
  //         icon: <PreviewIcon />,
  //         active: path === "viewdeals",
  //       },
  //     ],
  //   },
  //   {
  //     id: "Groups",
  //     children: [
  //       {
  //         id: "creategroup",
  //         title: "create group",
  //         icon: <CreateIcon />,
  //         active: path === "creategroup",
  //       },
  //       {
  //         id: "viewgroup",
  //         title: "view group",
  //         icon: <PreviewIcon />,
  //         active: path === "viewGroup",
  //       },
  //     ],
  //   },
  // ];

  useEffect(() => {
    const GET_USER_ROUTES_URL = "/api/auth/getuserroutes";
    const fetchUserRoutes = async () => {
      try {
        const fetchUserRoutes = await axiosPrivate.get(GET_USER_ROUTES_URL);
        // console.log(fetchUserRoutes, "fetchUserRoutes");
        setCategories(fetchUserRoutes.data.data);
        // categories = fetchUserRoutes.data.data;
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserRoutes();
  }, []);

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          sx={{ ...item, ...itemCategory, fontSize: 22, color: "#fff" }}
        >
          DEALS APP
        </ListItem>
        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: "#101F33" }}>
            {/* Parent Navigation Item */}
            <ListItem
              button
              onClick={() => handleToggle(id)}
              sx={{ py: 2, px: 3 }}
            >
              <ListItemText sx={{ color: "#fff" }}>{id}</ListItemText>
              {expandedSections[id] ? (
                <ExpandLessIcon sx={{ color: "#fff" }} />
              ) : (
                <ExpandMoreIcon sx={{ color: "#fff" }} />
              )}
            </ListItem>
            {/* Sub-navigation Items */}
            <Collapse in={expandedSections[id]} timeout="auto" unmountOnExit>
              {children.map(({ id: childId, title, icon }) => (
                <ListItem disablePadding key={childId}>
                  <ListItemButton
                    selected={path === childId}
                    sx={item}
                    onClick={() => navigate(childId)}
                  >
                    <ListItemIcon>{getIcon(icon)}</ListItemIcon>
                    <ListItemText className="capitalize">{title}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </Collapse>
            <Divider />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
