const DotComponent = ({ input = true }) => {
  return (
    <div
      className={`${
        input ? "bg-green-600 shadow-green-400 " : "bg-gray-300 shadow-gray-600"
      } rounded-full shadow-md  size-4`}
    ></div>
  );
};
export default DotComponent;
