import { useEffect, useState } from "react";
import CreateGroupComponent from "../../../Comp/Group/CreateGroup/CreateGroup";
import axios from "axios";
import { useLocation } from "react-router-dom";
const CreateGroup = () => {
  const [categories, setCategories] = useState([]);
  const [stores, setStores] = useState([]);
  const [rowData, setRowData] = useState({});

  useEffect(() => {
    const fetchStores = async () => {
      const response = await axios.get(
        "https://flipshope.com/api/prices/graphstores"
      );
      const stores = response.data.data.map((store) => ({
        value: store.site_id,
        label: store.store_name,
      }));
      setStores(stores);

      // console.log(response, "response");
    };
    fetchStores();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await axios.get(
        "https://flipshope.com/api/prices/getprodcat"
      );
      const categories = response.data.data.map((category) => ({
        value: category.cat_id,
        label: category.cat_name,
      }));
      setCategories(categories);

      // console.log(response, "response");
    };
    fetchCategories();
  }, []);

  const location = useLocation();
  const fullRowData = location?.state?.fullRowData;
  useState(() => {
    setRowData(fullRowData);
  }, [fullRowData]);
  // console.log(rowData, "rowData");
  // console.log(categories, "categories");
  // console.log(stores, "stores");

  return (
    <CreateGroupComponent
      categories={categories}
      stores={stores}
      rowData={rowData}
    />
  );
};
export default CreateGroup;
