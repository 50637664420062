import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import "./CreateForm.css"; // Import the CSS file
import TelegramIcon from "@mui/icons-material/Telegram";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import CustomInput from "../../Form/CustomInput";
import CustomImageShow from "../../Form/CustomImageShow";
import CustomButton from "../../Form/CustomButton";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import axios from "../../../api/axios/axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import UpdateIcon from "@mui/icons-material/Update";
import AppConstrains from "../../../AppConstrains/AppConstrains";
import { Image } from "antd";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CreateGroupComponent = memo(({ categories, stores, rowData = {} }) => {
  const categoriesOption = [
    { value: "all", label: "Select All" },
    ...categories,
  ];

  const storesOption = [{ value: "all", label: "Select All" }, ...stores];
  const [createGroupData, setCreateGroupData] = useState({
    defaultGroup: true,
    active: true,
  });
  const [selectedCategories, setSelectedCategories] = useState([]);
  //   const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  //   const [selectedStoreIds, setSelectedStoreIds] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [groupImage, setGroupImage] = useState(null);
  const navigate = useNavigate();
  const onlyDigits = /^\d+$/;
  // const [oldIcon, setOldIcon] = useState(null);
  const handelChange = (event) => {
    // console.log(event, "event");
    if (event.target.type === "checkbox") {
      setCreateGroupData({
        ...createGroupData,
        [event.target.name]: event.target.checked,
      });
    } else {
      setCreateGroupData({
        ...createGroupData,
        [event.target.name]: event.target.value,
      });
    }
  };

  // const activeOptions = useMemo(
  //   () => [
  //     { value: 1, label: "True" },
  //     { value: 0, label: "False" },
  //   ],
  //   []
  // );

  const picUpload = (e) => {
    const [file] = e.target.files;
    // console.log("pic uploading", file);
    setGroupImage(URL.createObjectURL(file));
    setCreateGroupData({
      ...createGroupData,
      icon: file,
    });
    // setFile(file);
  };

  // console.log(createGroupData, "createGroupData");
  //   //   console.log(selectedCategories, "selectedCategories");
  //   console.log(selectedCategoryIds, "selectedCategoryIds");
  //   console.log(keywords, "keywords");

  const handleCreateGroup = async () => {
    const formData = new FormData();
    // Object.keys(createGroupData).forEach((key) => {
    //   formData.append(key, createGroupData[key]);
    // });
    // formData.append("categories", selectedCategoryIds);
    // formData.append("stores", selectedStoreIds);
    // formData.append("keywords", keywords);

    formData.append("name", createGroupData.name || null);
    formData.append(
      "defaultGroup",
      JSON.stringify(createGroupData.defaultGroup)
    );
    formData.append("active", JSON.stringify(createGroupData.active));
    formData.append(
      "categories",
      JSON.stringify(createGroupData.categories || [])
    );
    formData.append("stores", JSON.stringify(createGroupData.stores || []));
    formData.append("keywords", JSON.stringify(keywords || []));
    formData.append(
      "priority",
      JSON.stringify(createGroupData.priority) || null
    );
    formData.append("icon", createGroupData.icon || null);

    try {
      const response = await axios.post(
        "/api/deals/addappdealsgroup",
        formData
      );

      toast.success(response.data.msg);
    } catch (error) {
      toast.error(error.response.data.msg);
    }

    // formData.forEach((key, value) => {
    //   console.log(value, "key");
    //   console.log(key, "value");
    // });
  };

  const handleUpdateGroup = async () => {
    const formData = new FormData();
    // Object.keys(createGroupData).forEach((key) => {
    //   formData.append(key, createGroupData[key]);
    // });
    // formData.append("categories", selectedCategoryIds);
    // formData.append("stores", selectedStoreIds);
    // formData.append("keywords", keywords);
    formData.append("_id", createGroupData?._id);
    formData.append("name", createGroupData.name || null);
    formData.append(
      "defaultGroup",
      JSON.stringify(createGroupData.defaultGroup)
    );
    formData.append("active", JSON.stringify(createGroupData.active));
    formData.append(
      "categories",
      JSON.stringify(createGroupData.categories || [])
    );
    formData.append("stores", JSON.stringify(createGroupData.stores || []));
    formData.append("keywords", JSON.stringify(keywords || []));
    formData.append("priority", JSON.stringify(createGroupData.priority));
    formData.append("icon", createGroupData.icon || null);
    if (typeof createGroupData.icon !== "string") {
      formData.append("oldIcon", createGroupData.oldIcon);
    }

    try {
      const response = await axios.put(
        "/api/deals/updateappdealsgroup",
        formData
      );

      toast.success(response.data.msg);
    } catch (error) {
      toast.error(error.response.data.msg);
    }

    // formData.forEach((key, value) => {
    //   console.log(value, "key");
    //   console.log(key, "value");
    // });
  };
  // console.log(typeof createGroupData.icon, "type");

  useEffect(() => {
    if (Object?.keys(rowData)?.length > 0) {
      // console.log(rowData, "rowData");
      setCreateGroupData({ ...rowData });

      if (rowData?.categories) {
        // console.log("in side of rowData.categoriesdf");
        setSelectedCategories(
          rowData?.categories
            ?.map((id) => {
              const cats = categories.find((cat) => cat.value === id);
              return cats;
            })
            ?.filter((x) => x)
        );
      }

      if (rowData?.stores) {
        setSelectedStores(
          rowData.stores
            .map((id) => {
              const str = stores.find((cat) => cat.value === id);
              return str;
            })
            .filter((x) => x)
        );
      }

      if (rowData?.icon) {
        setGroupImage(rowData?.icon);
      }

      if (rowData?.keywords) {
        setKeywords(rowData?.keywords);
      }
      if (rowData?.icon) {
        setCreateGroupData((prev) => ({ ...prev, ["oldIcon"]: rowData.icon }));
      }
    }
  }, [rowData, categories.length, stores.length]);

  console.log(createGroupData, "createGroupData");
  // console.log(selectedCategories, "selectedCategories");

  return (
    <div>
      <Paper
        className="create-form-container"
        // sx={{ display: `${Object.keys(dealsData).length ? "block" : "none"}` }}
      >
        <Typography variant="h5" component="h5" className="form-title">
          {rowData?._id ? "Update Group" : "Group Details"}
        </Typography>
        <div className="form-wrapper">
          <div className="form-row">
            <CustomInput
              label="Name"
              name="name"
              className="input-grow"
              value={createGroupData.name}
              handelChange={handelChange}
            />
          </div>
          <div className="form-row md-flex-row">
            <FormControlLabel
              control={
                <Checkbox
                  name="defaultGroup"
                  // defaultChecked
                  checked={createGroupData.defaultGroup}
                  onChange={handelChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Default"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="active"
                  // defaultChecked
                  checked={createGroupData.active}
                  onChange={handelChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Active"
            />
            {/* <CustomInput
              label="Default"
              className="input-grow md-half-width"
              name="default"
              value={createGroupData?.default}
              handelChange={handelChange}
              optionValue={true}
              withButton={true}
              activeOptions={activeOptions}
            />

            <CustomInput
              label="Active"
              className="input-grow md-half-width"
              name="active"
              value={createGroupData?.active}
              handelChange={handelChange}
              optionValue={true}
              withButton={true}
              activeOptions={activeOptions}
            /> */}
          </div>
          <div className="w-full form-row md-flex-row">
            <Autocomplete
              disableCloseOnSelect
              value={selectedCategories}
              onChange={(event, newValue) => {
                // console.log(newValue, "newValue");

                const isSelectAll = newValue.some(
                  (option) => option.value === "all"
                );

                if (isSelectAll) {
                  // If "Select All" is selected, toggle all options
                  if (selectedCategories.length < categories.length) {
                    setSelectedCategories(categories);
                    setCreateGroupData({
                      ...createGroupData,
                      categories: categories.map((c) => c.value),
                    });
                  } else {
                    setSelectedCategories([]);
                    setCreateGroupData({
                      ...createGroupData,
                      categories: [],
                    });
                  }
                } else {
                  // Handle regular selections
                  setSelectedCategories(newValue);
                  setCreateGroupData({
                    ...createGroupData,
                    categories: newValue.map((option) => option.value),
                  });
                }

                // setSelectedCategories(newValue);
                // setCreateGroupData({
                //   ...createGroupData,
                //   ["categories"]: newValue.map((option) => option.value),
                // });
                // setSelectedCategoryIds(newValue.map((option) => option.value));
              }}
              className="w-full"
              multiple
              id="categories"
              options={categoriesOption}
              getOptionLabel={(option) => option.label}
              //   defaultValue={[top100Films[13].title]}

              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip
                      variant="outlined"
                      label={option.label}
                      key={key}
                      {...tagProps}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Categories"
                  placeholder="Add Categories"
                />
              )}
            />
            <Autocomplete
              value={selectedStores}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                const isSelectAll = newValue.some(
                  (option) => option.value === "all"
                );

                if (isSelectAll) {
                  // If "Select All" is selected, toggle all options
                  if (selectedStores.length < stores.length) {
                    setSelectedStores(stores);
                    setCreateGroupData({
                      ...createGroupData,
                      stores: stores.map((c) => c.value),
                    });
                  } else {
                    setSelectedStores([]);
                    setCreateGroupData({
                      ...createGroupData,
                      stores: [],
                    });
                  }
                } else {
                  // Handle regular selections
                  setSelectedStores(newValue);
                  setCreateGroupData({
                    ...createGroupData,
                    stores: newValue.map((option) => option.value),
                  });
                }
                // console.log(newValue, "newValue");
                // setSelectedStores(newValue);
                // // setSelectedStoreIds(newValue.map((option) => option.value));
                // setCreateGroupData({
                //   ...createGroupData,
                //   ["stores"]: newValue.map((option) => option.value),
                // });
              }}
              className="w-full"
              multiple
              id="stores"
              options={storesOption}
              getOptionLabel={(option) => option.label}
              //   defaultValue={[top100Films[13].title]}

              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip
                      variant="outlined"
                      label={option.label}
                      key={key}
                      {...tagProps}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Stores"
                  placeholder="add Stores"
                />
              )}
            />
          </div>

          <div className="form-row">
            <Autocomplete
              multiple
              value={keywords}
              onChange={(event, newValue) => {
                // setKeywords(newValue);
                setKeywords(newValue);
              }}
              id="keywords"
              options={[]}
              //   defaultValue={[top100Films[13].title]}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip
                      variant="outlined"
                      label={option}
                      key={key}
                      {...tagProps}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Keywords"
                  placeholder="Add keywords"
                />
              )}
            />
          </div>
          <div className="flex justify-end w-full rounded"></div>
          <div className="form-row md-flex-row">
            <CustomInput
              label="Priority"
              className="w-1/2"
              name="priority"
              value={createGroupData.priority}
              handelChange={(e) => {
                if (e.target.value === "" || onlyDigits.test(e.target.value)) {
                  handelChange(e);
                }
              }}
            />
            {groupImage && typeof createGroupData?.icon !== "string" ? (
              <div className="flex gap-2">
                <div className="size-10">
                  <Image src={groupImage} />
                </div>
                <Button
                  onClick={() => {
                    setGroupImage(null);
                    setCreateGroupData({
                      ...createGroupData,
                      ["icon"]: null,
                    });
                  }}
                >
                  <ClearRoundedIcon className="cursor-pointer " />
                </Button>
              </div>
            ) : groupImage && typeof createGroupData?.icon === "string" ? (
              <div className="flex gap-2">
                <div className="size-10">
                  <Image
                    src={
                      `${AppConstrains.baseURL}/imagePath/group/` +
                      createGroupData?.icon
                    }
                  />
                </div>
                <Button
                  onClick={() => {
                    setGroupImage(null);
                    setCreateGroupData({
                      ...createGroupData,
                      ["icon"]: null,
                    });
                  }}
                >
                  <ClearRoundedIcon className="cursor-pointer " />
                </Button>
              </div>
            ) : (
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload files
                <VisuallyHiddenInput
                  type="file"
                  onChange={picUpload}
                  // onChange={(event) => console.log(event.target.files)}
                />
              </Button>
            )}
          </div>
        </div>

        <div
          className=""
          style={{
            marginTop: "40px",
            marginBottom: "15px",
            gap: "16px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {rowData?._id ? (
            <CustomButton
              value={`Update Group`}
              id="dealsApp"
              handleClick={handleUpdateGroup}
              className=""
              variant="outlined"
              // href="https://chatgpt.com/"
              // target="_blank"
              icon={UpdateIcon}
            />
          ) : (
            <CustomButton
              value={`Create Group`}
              id="dealsApp"
              handleClick={handleCreateGroup}
              className=""
              variant="outlined"
              // href="https://chatgpt.com/"
              // target="_blank"
              icon={AddCircleOutlineRoundedIcon}
            />
          )}
        </div>
      </Paper>
    </div>
  );
});

export default CreateGroupComponent;
