import { createContext, useState } from "react";

const StoreContext = createContext();

const StoreProvider = ({ children }) => {
  const [fetchAgain, setFetchAgain] = useState(false);
  const [auth, setAuth] = useState({});
  const [persist, setPersist] = useState(true);
  const [userProfile, setUserProfile] = useState({});
  // const [viewData, setViewData] = useState([]);
  // const [route,setRoute] = useState()
  // console.log(auth, "auth store");

  return (
    <StoreContext.Provider
      value={{
        fetchAgain,
        setFetchAgain,
        auth,
        setAuth,
        persist,
        userProfile,
        setUserProfile,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export { StoreProvider, StoreContext };
