import {
  Autocomplete,
  darken,
  FormControl,
  InputLabel,
  lighten,
  MenuItem,
  Paper,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { toast } from "react-toastify";

import UpdateIcon from "@mui/icons-material/Update";

import CustomInput from "../Form/CustomInput";
import CustomButton from "../Form/CustomButton";
import ActiveButton from "./ActiveButton";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import useStore from "../../Hooks/useStore";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.85),
  ...theme.applyStyles("dark", {
    backgroundColor: darken(theme.palette.primary.main, 0.8),
  }),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const UserUpdate = memo(({ rowData = {}, setOpen, routes = {} }) => {
  const { setFetchAgain } = useStore();

  const axiosPrivate = useAxiosPrivate();
  const [userData, setUserData] = useState({});
  const [selectedRole, setSelectedRole] = useState("");
  const [active, setActive] = useState(null);

  const roles = [
    { value: "100", tag: "Admin" },
    { value: "200", tag: "Developer" },
  ];
  const options = routes.map((option) => {
    const groupTitle = option.parentTitle;
    return {
      groupTitle,
      ...option,
    };
  });

  const handelChange = (event) => {
    // console.log(event, "event");
    if (event.target.type === "checkbox") {
      setUserData({
        ...userData,
        [event.target.name]: event.target.checked,
      });
    } else {
      setUserData({
        ...userData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleUpdateUserInfo = async () => {
    try {
      const UPDATE_USER_INFO_URL = "/api/auth/updateuserinfo";

      const response = await axiosPrivate.put(UPDATE_USER_INFO_URL, userData);
      toast.success(response.data.msg);
      setOpen(false);
      setFetchAgain((prev) => !prev);
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  useEffect(() => {
    if (Object?.keys(rowData)?.length > 0) {
      setUserData({ ...rowData });

      if (rowData.role) {
        setSelectedRole(rowData.role);
      }

      if (rowData.active === true || rowData.active === false) {
        setActive(rowData.active);
      }

      if (rowData?.modules?.length > 0) {
        // setSelectedRouteIds(rowData.modules);
        // setUserData((prev)=> ({...prev,[modules]}))
        // setUserData((prev) => ({
        //   ...prev,
        //   ["modules"]: routes.filter((option) =>
        //     rowData.modules.includes(option.routeId)
        //   ),
        // }));
        setSelectedRoutes(
          routes.filter((option) => rowData.modules.includes(option.routeId))
        );
      }
    }
  }, [rowData]);

  const [selectedRoutes, setSelectedRoutes] = useState([]);
  // const [selectedRouteIds, setSelectedRouteIds] = useState([]);
  // console.log(selectedRoutes, "selectedRoutes");
  // console.log(selectedRouteIds, "selectedRouteIds");
  // console.log(rowData, "rowData");
  console.log(userData, "userData");

  return (
    <div>
      <Paper className="create-form-container">
        <Typography variant="h5" component="h5" className="form-title">
          Update User
        </Typography>
        <div className="form-wrapper">
          <div className="form-row">
            <CustomInput
              label="_id"
              name="_id"
              className="input-grow"
              value={userData._id}
              disabled={true}
            />
          </div>
          <div className="form-row">
            <CustomInput
              label="Name"
              name="name"
              className="input-grow"
              value={userData.name}
              handelChange={handelChange}
            />
          </div>

          <div className="form-row">
            <CustomInput
              label="Email"
              name="email"
              className="input-grow"
              value={userData.email}
              handelChange={handelChange}
            />
          </div>
          <div>
            <Autocomplete
              multiple
              filterSelectedOptions
              disableCloseOnSelect
              value={selectedRoutes}
              onChange={(event, newValue) => {
                // console.log(newValue, "newValue");
                setUserData((prev) => ({
                  ...prev,
                  ["modules"]: newValue.map((option) => option.routeId),
                }));
                setSelectedRoutes(newValue);
                // setSelectedRouteIds(newValue.map((option) => option.routeId));
              }}
              options={options.sort(
                (a, b) => -b.groupTitle.localeCompare(a.groupTitle)
              )}
              groupBy={(option) => option.groupTitle}
              getOptionLabel={(option) => option.routeTitle}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Modules" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.routeId === value.routeId
              }
              renderGroup={(params) => {
                // console.log(params, "params");
                return (
                  <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                  </li>
                );
              }}
            />
          </div>
          <div className="">
            <ActiveButton
              name="active"
              checked={active}
              setActive={setActive}
              handelChange={handelChange}
              setUserData={setUserData}
            />
          </div>
          <div>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                name="role"
                value={selectedRole}
                label="role"
                onChange={(e) => {
                  handelChange(e);

                  setSelectedRole(e.target.value);
                }}
              >
                {roles.map((data) => {
                  return <MenuItem value={data.value}>{data.tag}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </div>
        </div>

        <div
          className=""
          style={{
            marginTop: "40px",
            marginBottom: "15px",
            gap: "16px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomButton
            value={`Update User`}
            id="updateuser"
            handleClick={handleUpdateUserInfo}
            className=""
            variant="outlined"
            // href="https://chatgpt.com/"
            // target="_blank"
            icon={UpdateIcon}
          />
        </div>
      </Paper>
    </div>
  );
});

export default UserUpdate;
