import { useState } from "react";
import UserInfoCard from "../../Comp/User/UserCard";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import UserUpdate from "../../Comp/User/UserUpdate";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "8px",
  boxShadow: 24,
  // p: 4,
};

const GridContainer = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
};

const UsersComponent = ({ users, routes }) => {
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [open, setOpen] = useState(false);
  const handleModalOpen = () => setOpen(true);

  return (
    <div style={GridContainer} className="border">
      {users.map((userData, index) => {
        return (
          <div
            className="cursor-pointer"
            onClick={() => {
              setSelectedUserData(userData);
              handleModalOpen();
            }}
          >
            <UserInfoCard key={index} data={userData} />
          </div>
        );
      })}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <UserUpdate
            rowData={selectedUserData}
            setOpen={setOpen}
            routes={routes}
          />
        </Box>
      </Modal>
    </div>
  );
};
export default UsersComponent;
