import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Pages/Layout";
// import Home from "./Pages/Home";
import View from "./Pages/View";
import Create from "./Pages/Create";
import { StoreProvider } from "./context/contextStore";
import SignUp from "./Pages/auth/signUp";
import SignIn from "./Pages/auth/signIn";
import useStore from "./Hooks/useStore";
import IsLogin from "./Pages/auth/IsLogin";
import PersistLogin from "./Pages/auth/PersistLogin";
import RequireAuth from "./Pages/auth/requireAuth";
import NotAuthorized from "./Pages/NotAuthorized";
import { Component } from "react";
import Profile from "./Pages/profile/Profile";
import Landing from "./Pages/Landing/Landing";
import CreateGroup from "./Pages/Group/CreateGroup/CreateGroup";
import ViewGroup from "./Pages/Group/ViewGroup/ViewGroup";
import Users from "./Pages/Users/Users";
function App() {
  // const { auth } = useStore();
  const roles = {
    ADMIN: 100,
    DEVELOPER: 200,
  };
  const allBased = [
    {
      path: "/viewdeals",
      component: <View />,
    },
    {
      path: "/createdeal",
      component: <Create />,
    },
    {
      path: "/profile",
      component: <Profile />,
    },
    {
      path: "/viewgroups",
      component: <ViewGroup />,
    },
    {
      path: "/creategroup",
      component: <CreateGroup />,
    },
    {
      path: "/users",

      component: <Users />,
    },
    // {
    //   path: "/routes",
    //   component: <Users />,
    // },
  ];
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route element={<PersistLogin />}>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Landing />} />
              {allBased.map((data, index) => {
                return (
                  <Route key={index} element={<RequireAuth path={data.path} />}>
                    <Route path={data.path} element={data.component} />
                  </Route>
                );
              })}
              {/* <Route element={<RequireAuth allowedRoles={[roles.ADMIN]} />}>
                <Route path="/create" element={<Create />} />
              </Route> */}
              {/* <Route
                element={
                  <RequireAuth allowedRoles={[roles.ADMIN, roles.DEVELOPER]} />
                }
              >
                <Route path="/view" element={<View />} />
              </Route> */}
            </Route>
          </Route>
          <Route path="*" element={<NotAuthorized />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
