import { Link, useLocation, useNavigate } from "react-router-dom";
import "./sign-in-form.css";
import { useState } from "react";
import axios from "../../../api/axios/axios";
import { toast } from "react-toastify";
import useStore from "../../../Hooks/useStore";

export function SignInForm() {
  const [loading, setLoading] = useState(false);
  const [FormData, setFormData] = useState({});
  const { setAuth, auth, persist, setPersist } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location?.state?.from?.pathname || "/";

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const LOGIN_API = "/api/auth/signin";

    try {
      // setTimeout(() => {}, 1000);
      const response = await axios.post(LOGIN_API, FormData, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
        },
      });
      // console.log(response, "response");
      // const accessToken = response?.data?.accessToken;
      // const role = response?.data?.role;
      // const modules = response?.data?.modules;
      setAuth(response?.data);
      toast.success(response?.data?.message);
      navigate(from, { replace: true });
    } catch (error) {
      if (error.response) toast.error(error.response.data.message);
      else toast.error(error.message);
    }
  };
  // console.log(auth, "auth");

  return (
    <div className="new-body">
      <div className="wrapper">
        <h2>Deals App</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-box">
            <input
              onChange={handleChange}
              type="text"
              name="email"
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="input-box">
            <input
              onChange={handleChange}
              type="password"
              name="password"
              placeholder=" Password"
              required
            />
          </div>

          <div className="input-box button">
            <input type="submit" value="Login" disabled={loading} />
          </div>
          <div className="text">
            <h3>
              Don't have an account? <Link to={"/signup"}>Sign up now</Link>
            </h3>
          </div>
        </form>
      </div>
    </div>
  );
}
