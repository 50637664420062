// import useAuth from "../../hooks/useAuth";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useStore from "../../Hooks/useStore";
const RequireAuth = ({ path }) => {
  const { auth } = useStore();
  const location = useLocation();

  return auth?.modules?.find((module) => "/" + module === path) ||
    auth?.role === "100" ? (
    <Outlet />
  ) : auth?.email ? (
    <Navigate to={"/notauthorized"} state={{ from: location }} replace />
  ) : (
    <Navigate to={"/signin"} state={{ from: location }} replace />
  );
};
export default RequireAuth;
